import { Component, ElementRef, Input, Output, EventEmitter, HostListener, ViewEncapsulation, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'scrawlbutton',
  templateUrl: './scrawlbutton.component.html',
  styleUrls: ['./scrawlbutton.component.css'],
  host: {
    '[class.is-focused]': 'isFocused',
    '[attr.tabindex]': '0',
    '[style.outline]': 'none' ,
    '[class.in-dialog]': 'isInDialog'
  },
})
export class ScrawlButtonComponent {
  @Input() color: string = 'blue';
  @Input() textColor: string;
  @Input() link: string;
  @Output() onClick = new EventEmitter<MouseEvent>();
  
  isFocused = false;
  isInDialog = false;

   constructor(
    private element: ElementRef,
    @Optional() private dialog: MatDialog  // Inject MatDialog
  ) {
    this.isInDialog = !!this.dialog;
  }

  // Add HostListener decorators for focus events
  @HostListener('focus')
  onHostFocus() {
    this.isFocused = true;
    console.log('Button focused'); // For debugging
  }

  @HostListener('blur')
  onHostBlur() {
    this.isFocused = false;
    console.log('Button blurred'); // For debugging
  }

  public focusButton() {
    const linkElement = this.element.nativeElement.querySelector('.scrawlbutton-link');
    if (linkElement) {
      linkElement.focus();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.onClick.emit(new MouseEvent('click'));
      if (this.link) {
        window.location.href = this.link;
      }
    }
  }

  onClickButton(event: MouseEvent) {
    this.onClick.emit(event);
  }
}