import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { OnInit } from "@angular/core";

//Used to create a single instance of the unity viewer

//#region Methods defined in JS file. This is so stupid
declare function createUnityInstance(canvas, config, onProgress): any;
//#endregion
interface UnityCallbacks {
  onSocketConnectionClosed: (data: string) => void;
}
declare global {
  interface Window {
    unityCallbacks: {
      onSocketConnectionToServerClosed: (data: string) => void;
    };
  }
}

@Injectable({
  providedIn: "root",
})
export class UnityViewer {
  gameInstance: any;
  containerId: string = "";
  viewerDiv: Element = null;
  private lobbyId = null;
  private currentLobbyId = null;
  public progress = 0;
  public isReady = false;
  private callbacks: UnityCallbacks | null = null;

  private renderer: Renderer2;
  constructor(rendererFactory: RendererFactory2) {
    // Get an instance of Angular's Renderer2
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  private setupUnityCallbacks() {
    // Define the callbacks object on window
    window.unityCallbacks = {
      onSocketConnectionToServerClosed: (lobbyData: string) => {
        console.log('Socket Connection closed!', lobbyData);
       // Call the callback if it exists
       if (this.callbacks?.onSocketConnectionClosed) {
        this.callbacks.onSocketConnectionClosed(lobbyData);
      }
    }
    };
  }

  clearCurrentLobbyId(){
    this.currentLobbyId = null;
  }
  showFullScreen() {
    if (this.gameInstance) {
      this.gameInstance.SetFullscreen(1);
    }
  }
  UpdateLobbyId(lobbyId) {
    this.lobbyId = lobbyId;
    if (lobbyId == this.currentLobbyId) return;

    if (this.gameInstance) {
      this.currentLobbyId = this.lobbyId;
      console.log("Sending lobby id to viewer");
      this.gameInstance.SendMessage(
        "JavascriptConnector",
        "ConnectToLobby",
        this.lobbyId
      );
    }
  }

  createDIV(containerId) {
    // Use Renderer2 to create the div element
    this.viewerDiv = this.renderer.createElement("div");
    // Set the id of the div
    this.renderer.setProperty(this.viewerDiv, "id", containerId);
    this.renderer.appendChild(document.body, this.viewerDiv);
  }

  InitializeViewer(containerId, useLegacyViewer, callbacks?: UnityCallbacks): void {
    this.callbacks = callbacks || null;
    this.setupUnityCallbacks();

    this.createDIV(containerId);
    var buildUrl = "";
    var loaderUrl = "";
    var config: any;
    if (useLegacyViewer) {
      buildUrl = "legacyviewer/Build";
      loaderUrl = buildUrl + "/WebGlAsm.asm.loader.js";
      config = {
        dataUrl: buildUrl + "/WebGlAsm.data",
        frameworkUrl: buildUrl + "/WebGlAsm.asm.framework.js",
        codeUrl: buildUrl + "/WebGlAsm.asm.js",
        memoryUrl: buildUrl + "/WebGlAsm.asm.mem",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "KevinRoyston",
        productName: "ScrawlBrawl",
        productVersion: "1.0",
      };
    } else {
      buildUrl = "viewer/Build";
      loaderUrl = buildUrl + "/WebGl.loader.js";
      config = {
        dataUrl: buildUrl + "/WebGl.data",
        frameworkUrl: buildUrl + "/WebGl.framework.js",
        codeUrl: buildUrl + "/WebGl.wasm",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "KevinRoyston",
        productName: "ScrawlBrawl",
        productVersion: "1.0",
      };
    }

    var container = document.querySelector("#unity-container");
    var canvas = document.querySelector("#unity-canvas");
    var loadingBar = document.querySelector("#unity-loading-bar");
    var progressBarFull = document.querySelector("#unity-load-viewer");
    var fullscreenButton = document.querySelector("#unity-fullscreen-button");
    var warningBanner = document.querySelector("#unity-warning");

    var el = container;
    this.renderer.setStyle(canvas, "width", el.clientWidth + "px");
    this.renderer.setStyle(
      canvas,
      "height",
      Math.round((el.clientWidth * 54) / 96) + "px"
    );

    createUnityInstance(canvas, config, (progress) => {
      if (progressBarFull) {
        this.renderer.setStyle(progressBarFull, "value", 100 * progress);
      }
    })
      .then((unityInstance) => {
        this.renderer.setStyle(loadingBar, "display", "none");

        this.gameInstance = unityInstance;
        unityInstance.Module.canvas.addEventListener(
          "wheel",
          function (event) {
            event.preventDefault();

            // Determine scroll direction
            const direction = event.deltaY > 0 ? 1 : -1;

            // Calculate scroll distance
            // Adjust the multiplier (3 in this case) to fine-tune the scroll speed
            const scrollDistance = Math.abs(event.deltaY) * 3;

            // Smooth scroll
            window.scrollBy({
              top: direction * scrollDistance,
              behavior: "smooth",
            });
          },
          { passive: false }
        );

        if (this.lobbyId) {
          unityInstance.SendMessage(
            "JavascriptConnector",
            "ConnectToLobby",
            this.lobbyId
          );
        }
      })
      .catch((message) => {
        alert(message);
      });
  }
}
