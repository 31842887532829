<br>
<div class="lobby-wait-header" *ngif="!waitingForGameStartParameters.IsHost">During the game you will need to look at both this device, and the game viewer
    <br>Make sure you can see the game viewer (your host should screen share it with you).
</div>

<div class="desktopContent" *ngif="!waitingForGameStartParameters.IsHost">
    Alternatively, if you would like to launch the viewer yourself, <span class="textLink" (click)="launchViewer()">click here.</span>
</div>

<br>
<div class="wait-button-list">
    <div class="leave-lobby-button">
        <scrawlbutton style="width:200px" (onClick)="onDeleteUser()">
            Leave Lobby
        </scrawlbutton>
    </div>
</div>